<template>
  <div class="login-box">
    <a-form
      id="components-form-demo-normal-login1"
      :form="form1"
      class="login-form"
      @submit="handleSubmitUser"
      ref="from1"
    >
      <a-form-item>
        <a-tooltip placement="right">
          <template slot="title"
            ><span class="tips">{{ $t(`loginUseNameTip`) }}</span>
          </template>

          <a-input
            class="username"
            autocomplete="off"
            @keyup.enter="handleSubmitUser"
            v-decorator="[
              'uname',
              {
                rules: [
                  {
                    required: true,
                    message: this.$t('userRule'),
                  },
                  {
                    pattern: /^[^\s]*$/,
                    message: this.$t('notEmpty'),
                  },
                ],
                initialValue: '',
              },
            ]"
            :placeholder="this.$t('placeholderUser')"
            style="line-height: 50px; width: 80%"
          >
            <a-icon
              slot="prefix"
              type="user"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-tooltip>
      </a-form-item>

      <a-form-item>
        <a-tooltip placement="right">
          <template slot="title"
            ><span class="tips">{{ $t(`passwordRule`) }}</span>
          </template>
          <!-- autocomplete="new-password" -->
          <a-input-password
            class="password"
            autocomplete="off"
            v-decorator="[
              'upass',
              {
                rules: [
                  {
                    required: true,
                    message: this.$t('passwordRule'),
                  },
                  {
                    pattern: /^[^\s]*$/,
                    message: this.$t('notEmpty'),
                  },
                ],
                initialValue: '',
              },
            ]"
            type="password"
            :placeholder="this.$t('placeholderPassword')"
            style="line-height: 50px; width: 80%"
          >
            <a-icon
              slot="prefix"
              type="lock"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input-password>
        </a-tooltip>
      </a-form-item>
      <a-form-item>
        <div class="codeinput">
          <a-input
            v-decorator="[
              'verCode',
              {
                rules: [
                  {
                    required: true,
                    message: this.$t('codeRule'),
                  },
                  {
                    len: 4,
                    message: this.$t('codeLenRule'),
                  },
                  {
                    pattern: /^[^\s]*$/,
                    message: this.$t('notEmpty'),
                  },
                ],
              },
            ]"
            :placeholder="this.$t('placeholderCode')"
            style="width: 70%"
          >
            <a-icon
              slot="prefix"
              type="safety-certificate"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
          <span @click="getCode()" class="codeimg1">
            <img class="verCode" :src="codesrc" alt="" />
          </span>
        </div>
      </a-form-item>
      <!-- 隐私政策 -->
      <a-form-item>
        <a-checkbox @change="onChange" :checked="checked"> </a-checkbox>
        <span class="prispan" @click="showModal"
          >&nbsp;&nbsp;{{ $t(`privacyPolicy`) }}</span
        >
      </a-form-item>
      <a-form-item>
        <a-button
          :disabled="!disableConfirm"
          type="primary"
          html-type="submit"
          class="login-form-button"
        >
          {{ $t(`login`) }}
        </a-button>
      </a-form-item>
    </a-form>
    <div>
      <a-modal
        class="primodal"
        v-model="visible"
        ok-text="确认"
        cancel-text="取消"
      >
        <iframe
          ref="iii"
          @load="iferror"
          id="ifclass"
          class="ifclass"
          :src="privacyUrl"
        ></iframe>

        <template slot="footer">
          <a-button key="back" @click="agreehideModal" type="primary">
            {{ $t(`agree`) }}
          </a-button>
          <a-button key="submit" @click="hideModal">
            {{ $t(`notAgree`) }}
          </a-button>
        </template>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { showmessage } from "../../../utils/showMessage";
import { selectUnitLang } from "@/utils/selectLanguage";
import { encrypt } from "@/utils/aes";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      privacyUrl:
        "https://privacy.xmeye.net/xmeye/privacy_" +
        sessionStorage.getItem("languagevalue") +
        ".html",
      visible: false,
      checked: false,
      showPrivacy: true,
      disableConfirm: false,
      checkCode: "",
      codesrc: "",
      token: "",
    };
  },
  beforeCreate() {
    this.form1 = this.$form.createForm(this, { name: "normal_login" });
  },
  created() {
    this.getCode();
  },
  computed: {
    changeLan: function () {
      return this.$store.state.changeLan;
    },
    // getToken:function(){
    //   return this.$store.state.accessToken.gohome;
    // }
  },

  watch: {
    changeLan() {
      this.form1.resetFields();
      this.privacyUrl =
        "https://privacy.xmeye.net/xmeye/privacy_" +
        sessionStorage.getItem("languagevalue") +
        ".html";
    },
    // getToken(){
    //   console.log('变化')
    //   // if(!this.$store.state.accessToken.accessToken){
    //   //   this.getCode()
    //   //   this.form1.resetFields(["verCode", ""]);
    //   // }else{
    //   //   this.form1.setFieldsValue({uname:this.$store.state.accessToken.nsname,upass:this.$store.state.accessToken.nspass}); //fofofo
    //   // }
    // }
  },
  mounted() {
    this.checked = true;
    this.disableConfirm = this.checked;
    if(this.$store.state.accessToken!=undefined&&this.$store.state.accessToken!=null&&this.$store.state.accessToken.length>0){
      if(this.$store.state.accessToken.gohome!=undefined&&this.$store.state.accessToken.gohome!=null&&this.$store.state.accessToken.gohome.length>0){
      this.form1.setFieldsValue({uname:this.$store.state.accessToken.nsname,upass:this.$store.state.accessToken.nspass}); //fofofo
    }
    }
    
  },
  destroyed() {},
  methods: {
    iferror() {
      var isRunUrl = function (url) {
        return new Promise(function (resolve, reject) {
          // 测试链接连通性, 主要检测404错误
          // 由于AJAX通常无法区分404和跨域问题
          // 所以只能用script 或者 link标签
          // link比script更容易捕获错误
          var dom = document.createElement("link");
          dom.href = url;
          dom.rel = "stylesheet";
          document.head.appendChild(dom);
          dom.onload = function () {
            document.head.removeChild(dom);
            resolve();
          };
          dom.onerror = reject;
        });
      };
      let that = this;
      isRunUrl(this.privacyUrl).then(
        function (data) {
          //处理resolve的代码
          console.log("resolve", data);
        },
        function (data) {
          //处理reject的代码
          console.log("reject", data);
          that.privacyUrl = "https://privacy.xmeye.net/xmeye/privacy_en.html";
        }
      );
    },
    showModal() {
      this.visible = true;
      this.iferror();
    },
    hideModal() {
      this.visible = false;
      this.checked = false;
      this.disableConfirm = this.checked;
    },
    agreehideModal() {
      this.visible = false;
      this.checked = true;
      this.disableConfirm = this.checked;
    },
    onChange() {
      this.checked = !this.checked;
      this.disableConfirm = this.checked;
    },
    // 获取验证码
    getCode() {
      this.$http.post("/transfer/image/v1/").then((res) => {
        if (res) {
          if (res.code != undefined && res.code != null && res.code == 200) {
            this.codesrc = "data:image/jpg;base64," + res.data.image;
            this.token = res.data.codeToken;
          } else {
            this.$message.destroy();
            this.$message.error(this.$t("" + showmessage(res)));
          }
        } else {
          this.$message.destroy();
          this.$message.error(this.$t("getcodefail"));
        }
      });
    },
    // 用户名登陆
    handleSubmitUser(e) {
      e.preventDefault();
      this.form1.validateFields((err, values) => {
        if (!err) {
          let nsname = values.uname
          let nspass = values.upass
          values.uname = encrypt(values.uname);
          values.upass = encrypt(values.upass);
          this.$http.post("/transfer/login/v1", values, {
              headers: {
                "Code-Token": this.token,
              },
            })
            .then((res) => {
              if (res.code == 4000) {
                this.$message.destroy();
                this.toolReset("error", "errorLogin1", 1, 1);
              } else if (res.code == 2000) {
                if(res.accessToken!=undefined&&res.accessToken!=null&&res.accessToken.length>0){
                var date = new Date(Date.now());
                var h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()) + '-';
                var m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()) + '-';
                var s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());
                var strDate =  h + m + s +'';
                this.$store.commit("SaveUserInfo", {
                  uname: values.uname,
                  upass: values.upass,
                  strDate:strDate,
                });
                this.$store.commit("ChangeAccesstoken",{accessToken:res.accessToken,nsname:nsname,nspass:nspass,gohome:false})
                sessionStorage.removeItem("index");
                sessionStorage.removeItem("searchvalue")
                localStorage.setItem("XM-Token", res.accessToken);
                  this.toolReset("success", "successLogin", 0, 0);
                  this.$router.push({ path: "/admin" });
                }else{
                  this.$message.destroy();
                  this.toolReset("error", this.$t(""+showmessage(res)), 1, 1);
                  // this.$message.error(this.$t(res.msg));
                }   
              } else if (res.code == -1) {
                this.toolReset("error", "errorLogin2", 1, 1);
              } else if (res.code == -2) {
                this.toolReset("error", "errorLogin3", 1, 1);
              } else if (res.code == 4049) {
                this.toolReset("error", "errorLogin4", 0, 0);
              } else {
                this.toolReset("error", this.$t(""+showmessage(res)), 1, 1);
              }
            });
        }
      });
    },
    changeCode(value) {
      this.checkCode = value;
    },
    //接收错误信息，及重置验证码
    toolReset(type, msg, code, reset) {
      this.$message.destroy();
      if (type == "success") {
        this.$message.success(this.$t(msg));
      } else if (type == "error") {
        this.$message.error(this.$t(msg));
      } else if (type == "warn") {
        this.$message.warning(this.$t(msg));
      }
      if (code == 1) {
        this.getCode();
      }
      if (reset == 1) {
        this.form1.resetFields(["verCode", ""]);
      }
    },

    // 设备登陆
    handleSubmitEquipment(e) {
      e.preventDefault();
      this.form2.validateFields((err, values) => {
        if (!err) {
          if (values.code.toLowerCase() == this.checkCode.toLowerCase()) {
            this.$router.push({ path: "/maclogin" });
            let password = values.password;
            if (password == undefined) {
              password = "";
            }
            setTimeout(() => {
              window.videoInfo = () => {
                return {
                  username: values.userName,
                  password: password,
                  mac: values.equipmentId,
                  lang: selectUnitLang(),
                };
              };
            }, 50);
          } else {
            this.$message.destroy();
            this.$message.error(this.$t("errorLogin2"));
            this.form2.resetFields(["code", ""]);
            this.$refs["ref_validateCode"].draw();
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.primodal >>> .ant-modal {
  width: 100% !important;
  height: 98%;
  position: relative;
  top: 0px;
  /* overflow: auto; */
}
.primodal >>> .ant-modal .ant-modal-close {
  display: none;
}
.primodal >>> .ant-modal-body {
  padding: 0;
}
.primodal >>> .ant-modal-footer {
  border-top-width: 0px;
}
.prispan {
  color: #1890ff;
  cursor: pointer;
}
.ifclass {
  width: 100%;
  height: 94vh;
  overflow: auto;
}
.verCode {
  width: 100px !important;
  height: 45px !important;
  border: 1px solid #409eff;
  border-radius: 4px;
  margin-bottom: 2px;
}
.codeinput {
  width: 80%;
  /* margin: 0 auto; */
}
.codeinput >>> .ant-input-affix-wrapper {
  float: left;
}
.codeimg1 {
  width: 100px;
  height: 45px;
  vertical-align: middle;
  float: right;
}
.login-box {
  margin-top: 30px;
}
.login-box >>> .ant-form-explain {
  text-align: left;
  margin-left: 10%;
}

.login-box >>> .ant-input-affix-wrapper .ant-input {
  text-align: left !important;
}

.login-box >>> .ant-tabs-nav-container {
  font-size: 20px;
}
.login-box >>> .ant-form-item-children {
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
}
.login-box >>> .ant-input {
  height: 45px !important;
  font-size: 14px;
}
.login-form-button {
  height: 45px !important;
  width: 80%;
  font-size: 18px !important;
}
.tips {
  display: block;
  font-size: 13px;
  max-width: 185px;
}
</style>
