<template>
  <a-drawer
    placement="top"
    :closable="false"
    :keyboard="false"
    :maskClosable="false"
    :visible="visibleCookie"
    height="auto"
  >
    {{ $t(`cookie`)
    }}<router-link :to="{ name: 'cookies' }"
      >Cookies{{ $t(`cookieLink`) }}</router-link
    >

    <div class="cookie">
      <a-button
        class="agree"
        @click="agreeCookie"
        type="primary"
        size="small"
        ghost
      >
        {{ $t(`agree`) }}
      </a-button>
      <a-button @click="notAgreeCookie" type="primary" size="small" ghost>
        {{ $t(`notAgree`) }}
      </a-button>
    </div>
  </a-drawer>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      agreedCookie: false,
      visibleCookie: true
    };
  },
  created() {
    this.visibleCookie = localStorage.getItem("showCookieUse")
      ? JSON.parse(localStorage.getItem("showCookieUse"))
      : this.visibleCookie;
    this.agreedCookie = localStorage.getItem("agreedCookie")
      ? JSON.parse(localStorage.getItem("agreedCookie"))
      : this.agreedCookie;
    if (this.agreedCookie == true) {
      this.init();
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  destroyed() {},
  methods: {
    //不同意cookie政策
    notAgreeCookie() {
      this.visibleCookie = false;
      localStorage.setItem("showCookieUse", this.visibleCookie);
    },
    // 同意cookie政策
    agreeCookie() {
      this.visibleCookie = false;
      localStorage.setItem("showCookieUse", this.visibleCookie);
      this.init();
      this.agreedCookie = true;
      localStorage.setItem("agreedCookie", this.agreedCookie);
    },

    //在同意使用cookie后再页面构建站长统计
    init() {
      this.$nextTick(() => {
        let script1 = document.createElement("script");
        let script2 = document.createElement("script");
        var box = document.createElement("span");
        box.setAttribute("id", "cnzz_stat_icon_1280059601");
        var link = document.createElement("a");
        link.href = "https://www.cnzz.com/stat/website.php?web_id=1280059601";
        var imgbox = document.createElement("img");
        imgbox.src = "https://icon.cnzz.com/img/pic1.gif";
        box.appendChild(link);
        link.appendChild(imgbox);
        setTimeout(() => {
         
          let f = document.getElementById("footer");
          f.appendChild(box);
         
        }, 10);
        script1.src = "https://s4.cnzz.com/z_stat.php?id=1280059601&show=pic1";
        script2.src =
          "https://s4.cnzz.com/core.php?web_id=1280059601&show=pic1&t=z";
          // console.log('哪一个1')
         
        document.body.append(script1);
        document.body.append(script2);
        // console.log('哪一个2')
      });
    }
  }
};
</script>

<style lang="less" scoped>
.cookie {
  margin-top: 1vh;
  margin-right: 1vw;
}
.agree {
  margin-right: 1.5vw;
}
</style>
