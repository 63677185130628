<template>
  <div id="components-layout-demo-basic">
    <a-layout>
      <a-layout-header>
        <Header />
      </a-layout-header>
      <a-layout-content>
        <div class="slider-img-box">
          <div class="content">
            <div class="imgclass">
              <img src="../../assets/image/loginbg.png" alt="" />
            </div>
            <div class="login1">
              <div class="loginclass">
                <p>{{ $t("userLogin") }}</p>
              </div>
                <LoginForm />
              <div class="regist">
                <div class="bottomdiv">
                  <div class="bleft">
                    <router-link to="/regist" class="login-form-regist">
                      {{ $t(`regist`) }}
                    </router-link>
                  </div>
                  <div class="bright">
                    <router-link
                      to="/fpassword"
                      class="login-form-forgot"
                      href=""
                    >
                      {{ $t(`findPassword`) }}
                    </router-link>
                  </div>
                </div>
              </div>
              <!-- </a-tab-pane>
              </a-tabs> -->
            </div>
          </div>
        </div>
      </a-layout-content>
      <a-layout-footer>
        <Footer />
      </a-layout-footer>
    </a-layout>
    <CookieDrawer />
  </div>
</template>

<script>
import Header from "../modules/header.vue";
import Footer from "../modules/footer.vue";
import CookieDrawer from "./modules/cookie-drawer.vue";
import LoginForm from "./modules/login-form.vue";
export default {
  components: { Header, CookieDrawer, Footer, LoginForm },
  data() {
    return {};
  },

  created() {
  },
  mounted(){

  },

  methods: {},
};
</script>

<style scoped>
/* 头部 */
#components-layout-demo-basic .ant-layout-header {
  width: 100%;
  min-height: 64px;
  padding: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
}

/* 内容 */
#components-layout-demo-basic .ant-layout-content {
  text-align: center;
}

.slider-img-box {
  display: flex;
  background-color: #f8f8f8;
  width: 100vw;
  height: calc(100vh - 128px);
  justify-content: center;
  align-items: center;
}
.imgclass {
  width: 26vw;
  min-width: 490px;
  height: 570px;
}
.imgclass img {
  width: 26vw;
  min-width: 490px;
  height: 570px;
}
.login-form-forgot {
  float: right;
}
.login-form-regist {
  float: left;
}
.loginclass {
  margin-left: 50px;
  text-align: left;
  font-size: 30px;
  height: 40px;
  margin-top: 15px;
  color: #000;
  margin-bottom: 40px;
}

.login1 {
  width: 27vw;
  min-width: 510px;
  height: 570px;
  background-color: #fff;
}
.login1 >>> .ant-tabs-nav .ant-tabs-tab:last-child {
  font-size: 18px;
}

.regist {
  display: flex;
  justify-content: space-between;
  padding: 10% 10%;
}

/* 底部 */
#components-layout-demo-basic .ant-layout-footer {
  background-color: #fff;
  padding: 0;
}
.content {
  display: flex;
  min-width: 1000px;
  width: 53vw;
  height: 570px;
}
.bottomdiv {
  border-top: 1px solid #DDDDDD;
  border-top-style: dashed;
  width: 80%;
  margin: 30px;
  margin-top: 0;
  padding-top: 30px;
  /* padding-left: 20px;
  padding-right: 20px; */
  display: flex;
  justify-content: center;
}
.bleft {
  height: 22px;
  /* padding-right: 30px; */
  padding-right: 10px;
}
.bright {
  padding-left: 10px;
  border-left: 1px solid #DDDDDD;
  height: 22px;
 
}
</style>
