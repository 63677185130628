var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-box"},[_c('a-form',{ref:"from1",staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login1","form":_vm.form1},on:{"submit":_vm.handleSubmitUser}},[_c('a-form-item',[_c('a-tooltip',{attrs:{"placement":"right"}},[_c('template',{slot:"title"},[_c('span',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t("loginUseNameTip")))])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'uname',
            {
              rules: [
                {
                  required: true,
                  message: this.$t('userRule'),
                },
                {
                  pattern: /^[^\s]*$/,
                  message: this.$t('notEmpty'),
                } ],
              initialValue: '',
            } ]),expression:"[\n            'uname',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: this.$t('userRule'),\n                },\n                {\n                  pattern: /^[^\\s]*$/,\n                  message: this.$t('notEmpty'),\n                },\n              ],\n              initialValue: '',\n            },\n          ]"}],staticClass:"username",staticStyle:{"line-height":"50px","width":"80%"},attrs:{"autocomplete":"off","placeholder":this.$t('placeholderUser')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmitUser.apply(null, arguments)}}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],2)],1),_c('a-form-item',[_c('a-tooltip',{attrs:{"placement":"right"}},[_c('template',{slot:"title"},[_c('span',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t("passwordRule")))])]),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'upass',
            {
              rules: [
                {
                  required: true,
                  message: this.$t('passwordRule'),
                },
                {
                  pattern: /^[^\s]*$/,
                  message: this.$t('notEmpty'),
                } ],
              initialValue: '',
            } ]),expression:"[\n            'upass',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: this.$t('passwordRule'),\n                },\n                {\n                  pattern: /^[^\\s]*$/,\n                  message: this.$t('notEmpty'),\n                },\n              ],\n              initialValue: '',\n            },\n          ]"}],staticClass:"password",staticStyle:{"line-height":"50px","width":"80%"},attrs:{"autocomplete":"off","type":"password","placeholder":this.$t('placeholderPassword')}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],2)],1),_c('a-form-item',[_c('div',{staticClass:"codeinput"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'verCode',
            {
              rules: [
                {
                  required: true,
                  message: this.$t('codeRule'),
                },
                {
                  len: 4,
                  message: this.$t('codeLenRule'),
                },
                {
                  pattern: /^[^\s]*$/,
                  message: this.$t('notEmpty'),
                } ],
            } ]),expression:"[\n            'verCode',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: this.$t('codeRule'),\n                },\n                {\n                  len: 4,\n                  message: this.$t('codeLenRule'),\n                },\n                {\n                  pattern: /^[^\\s]*$/,\n                  message: this.$t('notEmpty'),\n                },\n              ],\n            },\n          ]"}],staticStyle:{"width":"70%"},attrs:{"placeholder":this.$t('placeholderCode')}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"safety-certificate"},slot:"prefix"})],1),_c('span',{staticClass:"codeimg1",on:{"click":function($event){return _vm.getCode()}}},[_c('img',{staticClass:"verCode",attrs:{"src":_vm.codesrc,"alt":""}})])],1)]),_c('a-form-item',[_c('a-checkbox',{attrs:{"checked":_vm.checked},on:{"change":_vm.onChange}}),_c('span',{staticClass:"prispan",on:{"click":_vm.showModal}},[_vm._v("  "+_vm._s(_vm.$t("privacyPolicy")))])],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"disabled":!_vm.disableConfirm,"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("login"))+" ")])],1)],1),_c('div',[_c('a-modal',{staticClass:"primodal",attrs:{"ok-text":"确认","cancel-text":"取消"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('iframe',{ref:"iii",staticClass:"ifclass",attrs:{"id":"ifclass","src":_vm.privacyUrl},on:{"load":_vm.iferror}}),_c('template',{slot:"footer"},[_c('a-button',{key:"back",attrs:{"type":"primary"},on:{"click":_vm.agreehideModal}},[_vm._v(" "+_vm._s(_vm.$t("agree"))+" ")]),_c('a-button',{key:"submit",on:{"click":_vm.hideModal}},[_vm._v(" "+_vm._s(_vm.$t("notAgree"))+" ")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }